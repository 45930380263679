.root {
    font-family: 'Inter';
}
.header {
    color: #ffffff;
    height: 10vh;
    background-color: #8884d8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 25px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.header select {
    background-color: transparent;
    border: 1px solid #ffffff;
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    padding: 8px 12px;
    border-radius: 6px;
    cursor: pointer;
    appearance: none;
    position: relative;
    width: 200px;
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="%23FFFFFF" stroke-width="2" width="16" height="16"%3E%3Cpath d="M19 9l-7 7-7-7"%3E%3C/path%3E%3C/svg%3E');
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-size: 18px;
}

.header select:focus {
    outline: none;
}

.header .username {
    font-size: 18px;
    font-weight: 600;
    color: #E4E6EF;
}

.main {
    display: flex;
    height: 90vh;
}

.sidebar {
    background-color: #F1F5F9;
    color: #333333;
    width: 15%;
    padding: 50px 15px;
    box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.1);
}

.sidebar .sidebar-text {
    display: inline-block;
    margin-left: 10px;
    font-size: 16px;
} 

.sidebar .main-sidebar {
    padding: 10px;
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: 600;
    color: #444444;
    border-radius: 8px;
    transition: background-color 0.3s, color 0.3s;
    display: flex;
    align-items: center;
}

.sidebar .nested-sidebar {
    padding: 10px 5px;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    margin-bottom: 10px;
    color: #555555;
}

.sidebar .nested-sidebar:hover {
    background-color: #B8A8D8;
    color: #ffffff !important;
}

.sidebar .main-sidebar:hover {
    background-color: #B8A8D8;
    color: #ffffff !important;
}

.sidebar .icon {
    color: #F1F1F1; /* Light gray for icons */
    margin-right: 10px;
}

.content {
    width: 85%;
    overflow-y: auto;
    height: 100%;
    background-color: #FDFDFD;
}

.content-main {
    padding: 30px 25px;
    height: 100%;
}

.content-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.content select {
    background-color: transparent;
    border: 1px solid #444444;
    color: #444444;
    font-weight: 500;
    font-size: 14px;
    padding: 6px 12px;
    border-radius: 6px;
    cursor: pointer;
    appearance: none;
    position: relative;
    width: 250px;
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="%23444444" stroke-width="2" width="14" height="14"%3E%3Cpath d="M19 9l-7 7-7-7"%3E%3C/path%3E%3C/svg%3E');
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-size: 18px;
}

.content select:focus {
    outline: none;
}

.empty-state {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

.new-words-container {
    background-color: #F5F1F8;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 25px;
    margin-bottom: 30px;
}

.new-words-container h3 {
    margin-top: 0;
}

.cards-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin: 30px 0px;
}

.card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    flex: 1 1 30%;
}

.card h3 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #333;
}

.card h2 {
    font-size: 24px;
    font-weight: 600;
    color: #5C84A8;
    margin-bottom: 10px;
}

.card p {
    font-size: 16px;
    color: #555;
}

@media screen and (max-width: 768px) {
    .sidebar .sidebar-text {
      display: none; /* Hide text labels */
      margin: 0;
    }

    .sidebar {
        padding: 50px 5px;
        width: 10%;
    }

    .content {
        width: 90%;
    }
  
    .sidebar .main-sidebar {
      justify-content: center;
      padding: 5px
    }

    .content-head {
        flex-direction: column;
        align-items: start;
        margin-bottom: 10px;
    }
  }

  .word-head {
    display: flex;
    align-items: center;
    gap: 25px;
  }

  .word-main {
    margin-top: 25px;
  }
  .word-meaning {
    color: #444444;
    font-weight: 500;
    display: flex;
    gap: 10px;
    font-size: 20px;
    margin-bottom: 30px;
  }
  
  .usage-section {
    margin-bottom: 40px;
  }
  
  .section-title {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 15px;
    color: #2c3e50;
  }
  
  .highlight {
    background-color: #F5F1F8;
    border-left: 5px solid #5C84A8;
    padding: 10px 20px;
    margin-bottom: 15px;
  }

  .highlight ul {
    list-style-type: none;
    display: flex;
    align-items: center;
    padding: 0;
    gap: 20px;
    flex-wrap: wrap;
    margin-top: 20px;
}

.highlight ul li {
    padding: 8px 12px;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: auto;
    overflow-wrap: break-word;
}
  
  
  .usage-list {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .usage-item {
    border-bottom: 1px solid #f1f1f1;
  }
  
  .usage-item:last-child {
    border-bottom: none;
  }

  .highlight-cards {
    display: flex;
    gap: 30px;
    margin-bottom: 20px;
  }
  
  .usage-card {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    width: 50%;
    box-shadow: 0 2px 4px #8884d853;
  }
  
  .usage-card h4 {
    font-size: 1.2rem;
    color: #2c3e50;
    margin-top: 8px;
  }

  .usage-info-container {
    display: flex;
    gap: 30px;
  }
  
  .usage-card p {
    font-size: 0.9rem;
    color: #5d6869;
    margin: 0;
  }
  
  /* Table Style */
  .usage-table table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .usage-table th, .usage-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .usage-table th {
    background-color: #5C84A8;
    color: #ffffff;
  }
  
  .usage-table td {
    font-size: 1rem;
    color: #2c3e50;
  }