.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-col-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.m-0 {
    margin: 0;
}

.p-0 {
    padding: 0;
}

.text-primary {
    color: #5C84A8;
}
.text-secondary {
    color: #8884d8;
}

.text-success {
    color: #4CAF50;
}

.flex-between-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.h-full {
    height: 100%;
}

.h-screen {
    height: 100vh;
}

.w-screen {
    width: 100vh;
}

.w-1-4th {
    width: 25%;
}

.divider {
     border: 1px solid #5C84A8;
}

.table-container {
    margin: 20px;
}

.table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #cccccc;
    font-family: Arial, sans-serif;
}

.table th,
.table td {
    padding: 10px;
    border-bottom: 1px solid #d3d3d3;
}

.table tr {
    cursor: pointer;
}

.table tr:hover {
    background-color: #b8a8d820;
}

.table th {
    background-color: #5C84A8;
    color: #ffffff;
    font-weight: bold;
    text-align: left;
    cursor: pointer;
}

.table th div {
    display: flex;
    align-items: center;
    margin-right: 30px;
}

.table th div img {
    margin-left: 30px;
}

button {
    background-color: #5c84a8;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 50px;
}

.toggle {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-right: 25px;
}
.toggle input[type="checkbox"] {
    display: none;
}
.word {
    margin-right: 75px;
}
.togglelabel {
    color: #333333;
    position: relative;
    display: inline-block;
}

.toggle input[type="checkbox"] + label::before {
    content: ' ';
    display: block;
    height: 18px;
    width: 45px;
    border: 1px solid #B8A8D8;
    border-radius: 9px;
    position: absolute;
    top: 0;
    left: -65px;
    background: #E7F0FA;
    cursor: pointer;
    transition: all 0.3s ease-in;
}

.toggle input[type="checkbox"] + label::after {
    content: ' ';
    display: block;
    height: 30px;
    width: 30px;
    border: 1px solid #B8A8D8;   
    border-radius: 50%;
    position: absolute;
    top: -6px;
    left: -75px;
    background: #B8A8D8;
    transition: all 0.3s ease-in;
}

.toggle input[type="checkbox"]:checked + label::after {
    left: -40px; 
    background: #B8A8D8;
    transition: all 0.3s ease-in;
}

.toggle input[type="checkbox"]:checked + label::before {
    border-color: #B8A8D8;
    background: #f0f4f9;
}

.toggle input[type="checkbox"]:checked + label {
    color: #B8A8D8;
    font-weight: 700; 
}

